/** @jsx jsx */
import { jsx } from 'theme-ui'
import Img from 'gatsby-image'
import { format } from 'date-fns'
import { Link } from 'gatsby'

import Tag from 'src/components/tag'
import ClockIcon from 'src/assets/svgs/clock'
import EyeIcon from 'src/assets/svgs/eye'

const CardImage = ({
  isBig,
  slug,
  image,
  title,
  tags,
  date,
  timeToRead,
  views,
}) => (
  <div
    sx={{
      position: 'relative',
      height: '100%',
      borderRadius: 0,
      overflow: 'hidden',
    }}
  >
    <div
      sx={{
        position: 'absolute',
        zIndex: 3,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        px: 4,
        py: 3,
      }}
    >
      <div>
        {tags.map((tag) => (
          <Tag
            key={tag.name}
            name={tag.name}
            slug={tag.slug}
            color={tag.description}
          />
        ))}
      </div>
      <Link
        to={`/${slug}/`}
        title={title}
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          color: 'textOnDark',
          textDecoration: 'none',
        }}
      >
        <h2
          sx={{
            mb: 3,
            fontSize: isBig ? [3, 4, 4] : [3, 3, 3, 2],
            color: 'textOnDark',
            textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
          }}
        >
          {title}
        </h2>
        <span
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: 0,
          }}
        >
          {format(new Date(date), 'dd.MM.yy')}
          <ClockIcon sx={{ ml: 2, mr: 1 }} />
          {Math.round(timeToRead / 2)} мин
          <EyeIcon sx={{ ml: 2, mr: 1 }} />
          {views}
        </span>
      </Link>
    </div>

    <div
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background:
          'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%)',
        zIndex: 2,
      }}
    />

    {image.childImageSharp ? (
      <Img
        fluid={image.childImageSharp.fluid}
        sx={{ width: '100%', height: '100%' }}
      />
    ) : (
      <div
        sx={{
          width: '100%',
          height: '100%',
          background: `url(${image.publicURL}) center center / cover`,
        }}
      />
    )}
  </div>
)

export default CardImage
