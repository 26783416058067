/** @jsx jsx */
import { jsx } from 'theme-ui'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'

import {
  PROMOTION_EXTERNAL_URL,
  PROMOTION_TITLE,
} from 'src/constants/promotion'

const PromotionCardImage = ({ data: { image } }) => (
  <div
    sx={{
      position: 'relative',
      height: '100%',
      borderRadius: 0,
      overflow: 'hidden',
    }}
  >
    <div
      sx={{
        position: 'absolute',
        zIndex: 3,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        px: 4,
        py: 3,
      }}
    >
      <a
        href={PROMOTION_EXTERNAL_URL}
        target="_blank"
        rel="noreferrer"
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          color: 'textOnDark',
          textDecoration: 'none',
        }}
      >
        <h2
          sx={{
            mb: 3,
            fontSize: [3, 4, 4],
            color: 'textOnDark',
            textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
          }}
        >
          {PROMOTION_TITLE}
        </h2>
      </a>
    </div>

    <div
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background:
          'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%)',
        zIndex: 2,
      }}
    />

    <Img
      fluid={image.childImageSharp.fluid}
      sx={{ width: '100%', height: '100%' }}
    />
  </div>
)

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        image: file(relativePath: { eq: "promo.png" }) {
          childImageSharp {
            fluid(maxWidth: 680, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => <PromotionCardImage data={data} {...props} />}
  />
)
