/** @jsx jsx */
import { useContext } from 'react'
import { jsx } from 'theme-ui'
import { StaticQuery, graphql } from 'gatsby'

import { PostsViewsContext } from 'src/context/postsViewsContext'
import CardImage from 'src/components/cardImage'
import PromotionCardImage from 'src/components/promotionCardImage'
import { PROMOTION_ENABLED } from 'src/constants/promotion'

const HeroPosts = ({ data }) => {
  const {
    allWordpressPost: { nodes: posts },
    placeholder,
  } = data

  const { getPostViews } = useContext(PostsViewsContext)

  return (
    <div sx={{ pt: 2, pb: 7, bg: 'backgroundHero' }}>
      <div
        sx={{
          variant: 'boxes.container',
          px: [4, 4, 9],
          display: 'grid',
          gap: 3,
          gridTemplateColumns: ['1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr 1fr 1fr'],
          gridTemplateRows: [
            'repeat(5, 370px)',
            'repeat(3, 370px)',
            '405px repeat(2, 270px)',
            'repeat(2, 245px)',
          ],
          gridTemplateAreas: [
            `
              "post-0" 
              "post-1" 
              "post-2" 
              "post-3" 
              "post-4"
            `,
            `
              "post-0 post-0" 
              "post-1 post-2" 
              "post-3 post-4"
            `,
            `
              "post-0 post-0" 
              "post-1 post-2" 
              "post-3 post-4"
            `,
            `
              "post-0 post-0 post-1 post-2"
              "post-0 post-0 post-3 post-4"
            `,
          ],
        }}
      >
        {PROMOTION_ENABLED && (
          <div sx={{ gridArea: `post-0` }}>
            <PromotionCardImage />
          </div>
        )}
        {posts.slice(0, PROMOTION_ENABLED ? 4 : 5).map((post, i) => (
          <div
            key={post.id}
            sx={{ gridArea: `post-${PROMOTION_ENABLED ? i + 1 : i}` }}
          >
            <CardImage
              isBig={i === 0 && !PROMOTION_ENABLED}
              slug={post.slug}
              title={post.title}
              tags={post.categories}
              date={post.date}
              image={post.featured_media?.localFile ?? placeholder}
              timeToRead={post.fields.readingTime.minutes}
              views={getPostViews(post.wordpress_id) ?? post.fields.viewCount}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressPost(
          filter: { categories: { elemMatch: { name: { eq: "ТОП!" } } } }
          limit: 5
          sort: { fields: date, order: DESC }
        ) {
          nodes {
            wordpress_id
            id
            slug
            title
            excerpt
            date
            categories {
              name
              slug
              description
            }
            fields {
              readingTime {
                minutes
              }
              viewCount
            }
            featured_media {
              localFile {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 680, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
        placeholder: file(relativePath: { eq: "placeholder.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 680, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => <HeroPosts data={data} {...props} />}
  />
)
