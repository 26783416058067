import React from 'react'

import Layout from 'src/components/layout'
import SEO from 'src/components/seo'
import HeroPosts from 'src/components/heroPosts'
import PostsLint from 'src/components/postsList'

const IndexPage = () => (
  <Layout>
    <SEO title="Главная Страница" />
    <HeroPosts />
    <PostsLint
      isHomepage
      title="Цикл умного и полезного чтива"
      subtitle="Свежие статьи"
    />
  </Layout>
)

export default IndexPage
